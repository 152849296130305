import { setNotification } from 'actions/notification';
import Button from 'components/ui/button/Button';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const DMInvestors = () => {
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const { t: t_notify } = useTranslation('', { keyPrefix: 'notification' });

  const dispatch = useDispatch();

  return (
    <CardCTA>
      <CardCTA.Header>
        <span className='headline4-font'>{t_dm('investorsTitle')}</span>
        <span className='subtitle3-font'>{t_dm('investorsSubtitle')}</span>
      </CardCTA.Header>
      <CardCTA.Buttons>
        <Button
          lookAndFeel={'primary'}
          text={t_dm('context.copyLinkQuestionnaire')}
          size='small'
          onClick={() => {
            navigator.clipboard.writeText(
              'https://docs.google.com/forms/d/e/1FAIpQLScR9QV2de1N-BLEjoyiy6Ak_vMeJMu8z012Wg3WLE0UZ8rhvA/viewform'
            );
            dispatch(setNotification(t_notify('copyOnBoard')));
          }}
        />
      </CardCTA.Buttons>
    </CardCTA>
  );
};
