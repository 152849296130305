import { useTranslation } from 'react-i18next';
import { Status } from '../../../../../../types/utilsEnums/status';
import {
  PurchaseFramework,
  mapToPurchaseFramework
} from '../../../../../../utils/mapToPurchaseFramework';
import Icon from '../../../../../ui/icon/Icon';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
import { StatusTag } from '../../../../../ui/statusTag';
import { Column } from '../../../../../../types/table';
import Button, { ButtonLookAndFeel } from '../../../../../ui/button/Button';
import Label from '../../../../../ui/label/Label';
import { localStorageGetItem, localStorageSetItem } from '../../../../../../utils/localStorage';
import { useEffect, useState } from 'react';

type IPurchaseSupplierMocked = {
  id: string;
  socialReason: string;
  country: string;
  code: string;
  framework: string;
  purchasesCount: number;
  status: Status;
  co2e: number;
  risk: 'low' | 'medium' | 'high';
  riskButtonStatus: 'pending' | 'loading' | 'active';
};

export type IModalData = {
  id: string;
  companyName: string;
  risk: 'low' | 'medium' | 'high';
  scoreData: 'A' | 'B' | 'C';
  dataOrigin: string;
  companySize: string;
  carbonFootprint: number;
  reductionGoals: string;
  isoStandards: string;
  sustainabilityCertifications: string;
  emissionFactorSource: string;
};

const useMockedData = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'purchases.suppliersTable'
  });

  const mockedDataInit: IPurchaseSupplierMocked[] = [
    {
      id: '1',
      socialReason: 'APPLE SA',
      country: 'ES',
      code: '4741',
      framework: PurchaseFramework.CNAE,
      purchasesCount: 1000,
      status: Status.ACTIVE,
      co2e: 5989,
      risk: 'high',
      riskButtonStatus: 'pending'
    },
    {
      id: '2',
      socialReason: 'REPUESTOS MAX SL',
      country: 'ES',
      code: '5030',
      framework: PurchaseFramework.NACE,
      purchasesCount: 1203,
      status: Status.ACTIVE,
      co2e: 3989,
      risk: 'high',
      riskButtonStatus: 'pending'
    },
    {
      id: '3',
      socialReason: 'FRUTERIAS JAVI SL',
      country: 'ES',
      code: '5431',
      framework: PurchaseFramework.SIC,
      purchasesCount: 850,
      status: Status.ACTIVE,
      co2e: 2989,
      risk: 'medium',
      riskButtonStatus: 'pending'
    },
    {
      id: '4',
      socialReason: 'CAFETERÍA MARÍA SL',
      country: 'ES',
      code: '5813',
      framework: PurchaseFramework.SIC,
      purchasesCount: 500,
      status: Status.ACTIVE,
      co2e: 400,
      risk: 'medium',
      riskButtonStatus: 'pending'
    },
    {
      id: '5',
      socialReason: 'CARLIN SL',
      country: 'ES',
      code: '5943',
      framework: PurchaseFramework.SIC,
      purchasesCount: 200,
      status: Status.ACTIVE,
      co2e: 135,
      risk: 'low',
      riskButtonStatus: 'pending'
    },
    {
      id: '6',
      socialReason: 'FOLDER SA',
      country: 'US',
      code: '5081',
      framework: PurchaseFramework.SIC,
      purchasesCount: 107,
      status: Status.ACTIVE,
      co2e: 35,
      risk: 'low',
      riskButtonStatus: 'pending'
    },
    {
      id: '7',
      socialReason: 'IKEA IBERICA SA',
      country: 'US',
      code: '5719',
      framework: PurchaseFramework.SIC,
      purchasesCount: 20,
      status: Status.ACTIVE,
      co2e: 20,
      risk: 'low',
      riskButtonStatus: 'pending'
    }
  ];

  const modalDataArray: IModalData[] = [
    {
      id: '1',
      companyName: 'APPLE SA',
      risk: 'high',
      scoreData: 'A',
      dataOrigin: 'Dcycle IA',
      companySize: 'Grande',
      carbonFootprint: 324059,
      reductionGoals: 'SBTi',
      isoStandards: 'Más de 5',
      sustainabilityCertifications: 'Más de 3',
      emissionFactorSource: 'ACV certificado'
    },
    {
      id: '2',
      companyName: 'REPUESTOS MAX SL',
      risk: 'high',
      scoreData: 'B',
      dataOrigin: 'Proveedor',
      companySize: 'Pequeña',
      carbonFootprint: 35.84,
      reductionGoals: 'No',
      isoStandards: '2',
      sustainabilityCertifications: '1',
      emissionFactorSource: 'ACV estimado'
    },
    {
      id: '3',
      companyName: 'FRUTERIAS JAVI SL',
      risk: 'medium',
      scoreData: 'C',
      dataOrigin: 'Interno',
      companySize: 'Mediana',
      carbonFootprint: 1500,
      reductionGoals: 'Sí',
      isoStandards: '3',
      sustainabilityCertifications: '2',
      emissionFactorSource: 'Calculado interno'
    },
    {
      id: '4',
      companyName: 'CAFETERÍA MARÍA SL',
      risk: 'medium',
      scoreData: 'C',
      dataOrigin: 'Proveedor',
      companySize: 'Pequeña',
      carbonFootprint: 500,
      reductionGoals: 'No',
      isoStandards: '1',
      sustainabilityCertifications: '0',
      emissionFactorSource: 'Estimado'
    },
    {
      id: '5',
      companyName: 'CARLIN SL',
      risk: 'low',
      scoreData: 'C',
      dataOrigin: 'Interno',
      companySize: 'Pequeña',
      carbonFootprint: 100,
      reductionGoals: 'No',
      isoStandards: 'Ninguna',
      sustainabilityCertifications: 'Ninguna',
      emissionFactorSource: 'Estimado'
    },
    {
      id: '6',
      companyName: 'FOLDER SA',
      risk: 'low',
      scoreData: 'C',
      dataOrigin: 'Proveedor',
      companySize: 'Pequeña',
      carbonFootprint: 50,
      reductionGoals: 'No',
      isoStandards: 'Ninguna',
      sustainabilityCertifications: 'Ninguna',
      emissionFactorSource: 'Estimado'
    },
    {
      id: '7',
      companyName: 'IKEA IBERICA SA',
      risk: 'low',
      scoreData: 'C',
      dataOrigin: 'Interno',
      companySize: 'Grande',
      carbonFootprint: 25,
      reductionGoals: 'No',
      isoStandards: 'Ninguna',
      sustainabilityCertifications: 'Ninguna',
      emissionFactorSource: 'Estimado'
    }
  ];

  const [data, setData] = useState<IPurchaseSupplierMocked[]>([]);
  const [modalData, setModalData] = useState<IModalData | undefined>();

  useEffect(() => {
    const mockedData = localStorageGetItem('mocked-data');

    if (!mockedData) localStorageSetItem('mocked-data', mockedDataInit);
    else setData(mockedData);
  }, []);

  const selectModalData = (id: string) => {
    const modalData = modalDataArray.find((el) => el.id === id);
    setModalData(modalData);
  };

  const handleCloseModalData = () => {
    setModalData(undefined);
  };

  const onClick = (id: string) => () => {
    // if loading do nothing
    if (data.find((el) => el.id === id)?.riskButtonStatus === Status.LOADING) return;

    // get element
    const newElement = data.find((el) => el.id === id);

    // if already active show prorper modal and return
    if (newElement?.riskButtonStatus === Status.ACTIVE) {
      return selectModalData(id);
    }

    // set loading
    (newElement as any).riskButtonStatus = Status.LOADING;

    // update data
    const newData = data.map((element) => {
      if (element.id === id && newElement) return newElement;
      return element;
    });

    // update data and local storage (LOADING)
    setData(newData);
    localStorageSetItem('mocked-data', newData);

    // simulate loading, wait 3 seconds and update to active
    setTimeout(() => {
      // get element to update and set to active
      const updatedElement = data.find((el) => el.id === id);
      (updatedElement as any).riskButtonStatus = Status.ACTIVE;

      // update data
      const updatedData = data.map((element) => {
        if (element.id === id && updatedElement) return updatedElement;
        return element;
      });

      // update data and local storage (ACTIVE)
      setData(updatedData);
      localStorageSetItem('mocked-data', updatedData);
    }, 3000);
  };

  const columns: Column<IPurchaseSupplierMocked>[] = [
    {
      title: t('columns.supplier'),
      key: 'name',
      dataIndex: 'socialReason',
      width: 600
    },
    {
      title: t('columns.country'),
      key: 'country',
      dataIndex: 'country',
      width: 100
    },
    {
      title: t('columns.code'),
      key: 'code',
      dataIndex: 'code',
      width: 100
    },
    {
      title: t('columns.codeType'),
      key: 'codeType',
      dataIndex: 'framework',
      render: (framework) => (framework ? mapToPurchaseFramework(framework) : '-'),
      width: 200
    },
    {
      title: t('columns.purchasesCount'),
      key: 'purchasesCount',
      dataIndex: 'purchasesCount',
      width: 200
    },
    {
      title: t('columns.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status: Status) => {
        if (status === 'active') {
          return <StatusTag status={Status.ACTIVE} text={t(`tags.${status}`)} />;
        }

        return (
          <>
            <TooltipWrapper text={t(`tooltip.${status}`)} position='top'>
              <StatusTag status={status as Status} text={t(`tags.${status}`)} />
            </TooltipWrapper>
          </>
        );
      },
      width: 150
    },
    {
      title: t('columns.co2e'),
      key: 'co2e',
      dataIndex: 'co2e',
      render: (co2e) => {
        return <span className='highlight-text-color small-card-font'>{co2e} kg</span>;
      },
      width: 150
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'id',
      align: 'right',
      render: (id) => (
        <TooltipWrapper shouldAppear={true} position='top' text={t('tooltip.cannotEdit')}>
          <Icon
            icon='edit'
            color={'gradient'}
            className='pointer'
            onClick={() => null}
            style={{ cursor: 'pointer' }}
          />
        </TooltipWrapper>
      )
    }
  ];

  return { columns, data, modalData, handleCloseModalData };
};

export default useMockedData;
