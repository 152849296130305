import { useContext } from 'react';
import { UserContext } from '../context/userContext';
import useSelectedOrganization from './useSelectedOrganization';
import { User } from '../types/entities/user';
import { Organization } from '../types/entities/organization';
import { useQuery } from '@tanstack/react-query';
import { getIsOwnerOfParent } from 'services/api/getIsOwnerOfParent';

const checkIsOwner = (user: User | null, selectedOrg?: Organization) => async () => {
  if (!user || !selectedOrg) {
    return false;
  }

  if (selectedOrg?.role === 'owner') {
    return true;
  }

  if (selectedOrg?.role === 'parent') {
    return await getIsOwnerOfParent(user.id, selectedOrg.id);
  }

  return false;
};

export const useIsOwner = (enabled = true) => {
  const user = useContext(UserContext);
  const selectedOrg = useSelectedOrganization();

  const query = useQuery({
    queryKey: ['isOwner', user?.id, selectedOrg?.id],
    queryFn: checkIsOwner(user, selectedOrg),
    enabled
  });

  return { isOwner: query.data ?? false, isLoading: query.isLoading };
};
