import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useColumns } from '../../hooks/useColumns';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import { useContext, useEffect, useState } from 'react';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import Select from 'components/ui/formComponents2/select/Select';
import { useTranslation } from 'react-i18next';
import { useObtainTopicsData } from '../../hooks/useObtainTopicsData';
import { FilterDatesContext } from 'context/filterDatesContext';
import { useModifyTopic } from '../../hooks/useModifyTopic';
import Icon from 'components/ui/icon/Icon';

const availableFilters = [
  { id: 'all', name: 'All' },
  { id: 'material', name: 'Material' },
  { id: 'non-material', name: 'Non-material' }
];

export const DMResults = () => {
  const {
    t,
    i18n: { resolvedLanguage }
  } = useTranslation();
  const { startDate } = useContext(FilterDatesContext);
  const year = startDate.split('/')[2];

  // Filter params
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState<string | undefined>();

  const { data, fetchNextPage, isLoading } = useObtainTopicsData({
    year,
    language: resolvedLanguage
  });
  const { topics, total } = data ?? {};
  const { mutate } = useModifyTopic({ year, language: resolvedLanguage });
  const [filteredTopics, setFilteredTopics] = useState(topics ?? []);
  const { columns } = useColumns({
    setImpactScore: (id: string, impactScore) => {
      mutate({
        id,
        changes: { impact_score: impactScore },
        year
      });
    },
    setFinancialScore: (id: string, financialScore) => {
      mutate({
        id,
        changes: { financial_score: financialScore },
        year
      });
    },
    setMeasured: (id: string, isMeasured) => {
      mutate({
        id,
        changes: { being_measured: isMeasured },
        year
      });
    }
  });

  useEffect(() => {
    if (!topics) return;

    const filtered = topics.filter((topic) => {
      const searchMatch =
        topic.category.toLowerCase().includes(searchValue.toLowerCase()) ||
        topic.topic.toLowerCase().includes(searchValue.toLowerCase()) ||
        topic.subtopic.toLowerCase().includes(searchValue.toLowerCase());

      if (filterValue === 'material') {
        return topic.material && searchMatch;
      } else if (filterValue === 'non-material') {
        return !topic.material && searchMatch;
      } else {
        return searchMatch;
      }
    });

    setFilteredTopics(filtered);
  }, [topics, searchValue, filterValue]);

  return (
    <InfiniteList
      containerStyle={{
        height: '72vh',
        marginTop: 0
      }}
      fetchData={fetchNextPage}
      data={filteredTopics}
      columns={columns}
      loading={isLoading}
      firstLoading={isLoading}
      total={total}
      header={
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center gap-2'>
            <InputWrapper icon={'/images/icons/search.svg'}>
              <InputText
                placeholder={t('esgMetrics.filters.search')}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </InputWrapper>

            {/* Select Dropdown for Mandatory Filter */}
            <InputWrapper icon={'/images/icons/filter.svg'}>
              <Select
                value={
                  filterValue === undefined
                    ? { id: 'all', name: t('All') }
                    : filterValue === 'material'
                    ? { id: 'material', name: t('Material') }
                    : { id: 'non-material', name: t('Non-material') }
                }
                placeholder={t('esgMetrics.filters.all')}
                options={availableFilters}
                onChange={(option) => {
                  if (option.id === 'all') {
                    setFilterValue(undefined);
                  } else if (option.id === 'material') {
                    setFilterValue('material');
                  } else {
                    setFilterValue('non-material');
                  }
                }}
              />
            </InputWrapper>
            <div className='flex gap-2 align-center p-1 rounded-4 app-bg-color'>
              <Icon icon='multi_star' color={'blocked'} />
              <span className='secondary-text-color font-12'>
                {t('doubleMateriality.topics.materialityExplanation')}
              </span>
            </div>
          </div>

          <TotalLegend total={total ?? 0} loading={false} i18key={'doubleMateriality.topics'} />
        </div>
      }
    />
  );
};
