import { useContext, useEffect } from 'react';
import { FilterDatesContext } from '../../../context/filterDatesContext';
import { ROUTES } from '../../../constants/routes';
import { ESGModeContext } from '../../../context/esgModeContext';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import useYearOptions from '../../../customHooks/useYearOptions';
import { convertStringToDate } from '../../../utils/convertDates';
import { localStorageSetItem } from '../../../utils/localStorage';
import Button from '../../ui/button/Button';
import InputCalendar from '../../ui/formComponents/inputCalendar/InputCalendar';
import FormSelect from '../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { HeadwayBadge } from '../../ui/headwayBadge/HeadwayBadge';
import Icon from '../../ui/icon/Icon';
import NotificationsButton from './notifications/NotificationsButton';
import UserButton from './profile/UserButton';
import './styles.scss';
import SwitchOrganization from './switchOrganization/SwitchOrganization';
import TasksButton from './tasks/TasksButton';

type Props = {
  showFilterDates: boolean;
};

function Header({ showFilterDates }: Props) {
  const selectedOrganization = useSelectedOrganization();

  const { startDate, endDate, setStartDate, setEndDate, setDateError } =
    useContext(FilterDatesContext);

  const { mode } = useContext(ESGModeContext);

  useEffect(() => {
    if (mode === 'controlPanel') {
      const currentYear = new Date().getFullYear();
      handleChangeYear({ id: currentYear.toString(), name: currentYear.toString() });
    }
  }, [mode]);

  const handleChangeStartDate = (value: string) => {
    setStartDate(value);
    // change start_date_dashboard session_storage property
    localStorageSetItem('start_date_dashboard', value);
  };

  const handleChangeEndDate = (value: string) => {
    setEndDate(value);
    // change end_date_dashboard session_storage property
    localStorageSetItem('end_date_dashboard', value);
  };

  const handleDateError = (error?: ErrorType) => setDateError(error);
  const endDateOfCurrentYear = new Date(new Date().getFullYear(), 11, 31);

  const handleChangeYear = (value: SelectOptionFormat) => {
    handleChangeStartDate(`01/01/${value.id}`);
    handleChangeEndDate(`31/12/${value.id}`);
  };

  const renderFilterInput = () => {
    if (!showFilterDates) return;
    if (mode === 'controlPanel') {
      return (
        <FormSelect
          iconV2='calendar'
          value={{
            id: year.toString(),
            name: year.toString()
          }}
          onChange={handleChangeYear}
          options={yearOptions.years}
          sort={false}
        />
      );
    }
    const pathname = window.location.pathname;
    if (pathname.includes(ROUTES.MEASURE_ESG_METRICS)) {
      return (
        <FormSelect
          iconV2='calendar'
          value={{
            id: year.toString(),
            name: year.toString()
          }}
          onChange={handleChangeYear}
          options={yearOptions.years}
          sort={false}
        />
      );
    }
    return (
      <InputCalendar
        mode='range'
        handleChangeStartDate={handleChangeStartDate}
        handleChangeEndDate={handleChangeEndDate}
        startDateValue={startDate}
        endDateValue={endDate}
        maxStartDate={new Date()}
        maxEndDate={endDateOfCurrentYear}
        predefinedDates
        height='30px'
        fontClass='body1-font'
        handleDateError={handleDateError}
      />
    );
  };

  const yearOptions = useYearOptions();

  const year = convertStringToDate(startDate).getFullYear();

  return (
    <header>
      <div id='date' className={`header-dates ${showFilterDates ? '' : 'hide'}`}>
        {renderFilterInput()}
      </div>
      <div className='header-options'>
        <SwitchOrganization />
        {!(process.env.REACT_APP_PARTNER === 'edp') && (
          <HeadwayBadge>
            <Button
              lookAndFeel='tertiary'
              size='small'
              iconNode={<Icon icon='rocket' color='blocked' />}></Button>
          </HeadwayBadge>
        )}
        {selectedOrganization?.role !== 'fund' && <NotificationsButton />}
        {selectedOrganization?.role !== 'fund' && <TasksButton />}
        <UserButton />
      </div>
    </header>
  );
}

export default Header;
