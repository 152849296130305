import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FilterOptionType } from 'components/layout/Filters/FilterSection';
import { deleteInvoice, obtainInvoicesByFacility, updateInvoice } from 'services/api/invoices';
import { groupBy } from 'utils/arrays';
import { convertDateFrontToBackTimestamp } from 'utils/convertDates';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'lib/ReactQuery/client';
import { createInvoice } from 'services/api/invoices';
import { InvoiceBody } from '../components/createInvoice/constants';

type useObtainFacilitiesProps = {
  id: string;
  category: string;
  sortBy: { id: string; direction: string };
  filters: FilterOptionType[];
  dates?: { startDate: string; endDate: string };
  searchValue?: string;
};

export const useObtainFacilities = ({
  id,
  category,
  sortBy,
  filters,
  dates,
  searchValue
}: useObtainFacilitiesProps) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: [
      'facility',
      id,
      { category, filters: buildFilters(filters, dates, searchValue), sortBy }
    ],
    queryFn: obtainInvoicesByFacility,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    }
  });
  return { data, isLoading, fetchNextPage, hasNextPage, isFetching };
};

const buildFilters = (
  filters: FilterOptionType[],
  dates?: { startDate: string; endDate: string },
  searchValue?: string
) => {
  const groupedFilters: Record<string, FilterOptionType[]> = groupBy(filters, 'type');
  let processedFilters: IFilter[] = Object.keys(groupedFilters).map((key) => {
    const values = groupedFilters[key].map((value) => value.id);
    return {
      field: key,
      value: values,
      type: 'in'
    };
  });

  if (dates) {
    processedFilters = processedFilters.concat([
      {
        field: 'start_date',
        value: [
          convertDateFrontToBackTimestamp(dates.startDate),
          convertDateFrontToBackTimestamp(dates.endDate)
        ],
        type: 'rd' as FilterType
      },
      {
        field: 'end_date',
        value: [
          convertDateFrontToBackTimestamp(dates.startDate),
          convertDateFrontToBackTimestamp(dates.endDate)
        ],
        type: 'rd' as FilterType
      }
    ]);
  }

  if (searchValue) {
    processedFilters.push({
      field: 'cups',
      value: searchValue,
      type: 'il'
    });
  }

  return processedFilters;
};

export const useAddInvoice = () => {
  const { mutate, mutateAsync } = useMutation({
    mutationFn: async ({ invoice }: { invoice: InvoiceBody }) => {
      return await createInvoice(invoice, false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['facility'] });
    }
  });

  return { mutate, mutateAsync };
};

export const useEditInvoice = () => {
  const { mutateAsync } = useMutation({
    mutationFn: async ({ id, invoice }: { id: string; invoice: InvoiceBody }) => {
      return await updateInvoice(id, invoice);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['facility'] });
    }
  });

  return { mutateAsync };
};

export const useDeleteInvoice = () => {
  const { mutateAsync } = useMutation({
    mutationFn: async ({ id, organizationId }: { id: string; organizationId: string }) => {
      return await deleteInvoice(id, organizationId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['facility'] });
    }
  });

  return { mutateAsync };
};
