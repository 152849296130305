import { AxiosPromise } from 'axios';
import moment from 'moment';
import {
  InvoiceBody,
  InvoicePostBack
} from '../../components/measure/facilities/facilityDetail/components/createInvoice/constants';
import { InvoicesResponse, InvoiceToUpload } from '../../types/entities/invoice';
import apiFetch from '../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl } from 'utils/url';
import { QueryFunction } from '@tanstack/react-query';
import { queryClient } from 'lib/ReactQuery/client';

export const getFacilityFuels = async (country: string) => {
  try {
    const response = await apiFetch('GET', `/facility_fuels`, null, null, {
      country
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getSuppliers = async (country: string) => {
  try {
    const response = await apiFetch('GET', `/suppliers`, null, null, {
      country
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

type FetchInvoicesByFacilityQueryKey = [
  string,
  string,
  {
    sortBy: { id: string; direction: string };
    category: string;
    filters: IFilter[];
  }
];

export const obtainInvoicesByFacility: QueryFunction<
  InvoicesResponse,
  FetchInvoicesByFacilityQueryKey,
  number
> = async ({ queryKey, pageParam = 1 }) => {
  const [_, facility_id, options] = queryKey;
  const { category, filters, sortBy } = options;
  const URL = `/invoices/casted/facility/${facility_id}`;
  // add sort_by, page and filters
  const { data } = await apiFetch(
    'GET',
    getUrl(URL, {
      queryParams: generateQueryParamsFromObject({
        filters: category,
        page: pageParam
      }),
      filters: filters,
      sorters: [{ field: sortBy.id, order: sortBy.direction as 'asc' | 'desc' }]
    })
  );
  return data;
};

export const createInvoice = async <T extends boolean>(
  data: InvoiceBody,
  returnRequest: T = false as T
): Promise<
  (T extends true ? AxiosPromise<InvoicePostBack> : InvoicePostBack) | null | { error: any }
> => {
  try {
    const request: any = apiFetch('POST', `/invoices`, data);

    if (returnRequest) {
      return request;
    }

    const response = await request;

    return response.data;
  } catch (err) {
    return { error: err };
  }
};

export const createInvoiceEmissionFactor = async <T extends boolean>(
  data: InvoiceBody,
  returnRequest: T = false as T
): Promise<(T extends true ? AxiosPromise<InvoicePostBack> : InvoicePostBack) | null> => {
  try {
    const request: any = apiFetch('POST', `/invoices/emission_factor`, data);

    if (returnRequest) {
      return request;
    }

    const response = await request;

    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateInvoice = async (id: string, data: InvoiceBody) => {
  try {
    const response = await apiFetch('PUT', `/invoices/${id}`, data);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const uploadInvofox = async (data: InvoiceToUpload, returnRequest = false) => {
  try {
    const request = await apiFetch('POST', '/invofox', data);
    if (returnRequest) {
      return request;
    }
    return request.data;
  } catch (err) {
    return err;
  }
};

export const deleteInvoice = async (id: string, organization_id: string) => {
  try {
    const response = await apiFetch('DELETE', `/invoices/${id}`, null, {
      'x-organization-id': organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getRenewableEnergyKPI = async (
  start_date: Date,
  end_date: Date,
  combustion = false,
  returnRequest = false
) => {
  try {
    // convert dates from dd/mm/yyyy to timestamp
    const startDateTimestamp = moment(start_date).unix();
    const endDateTimestamp = moment(end_date).unix();
    const request = apiFetch(
      'GET',
      '/invoices/kpi/renewable_energy',
      {},
      {},
      {
        start_date: startDateTimestamp,
        end_date: endDateTimestamp,
        combustion
      }
    );
    if (returnRequest) return request;
    const response = await request;
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getInvoiceById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/invoices/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getSuppliersByFacilityId = async (facility_id: string) => {
  try {
    const response = await apiFetch('GET', `/suppliers/facility/${facility_id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getFacilityFuelsByFacilityId = async (facility_id: string) => {
  try {
    const response = await apiFetch('GET', `/facility_fuels/facility/${facility_id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getLastUsedFacilityPercentages = async (facility_id: string, type: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/invoices/last_facility_percentage_used/${facility_id}`,
      null,
      {},
      {
        type
      }
    );
    return response.data;
  } catch (err) {
    return [];
  }
};
