import { forwardRef, InputHTMLAttributes } from 'react';
import { InputSize } from '../../../../types/utilsEnums/input';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: InputSize;
  wrapperClassName?: string;
}

const InputText = forwardRef<HTMLInputElement, Props>(
  ({ maxLength = 100, size = InputSize.MEDIUM, className, ...props }, ref) => {
    const { t } = useTranslation();
    const sizeClassName = size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';
    const newClassName = `input-text ${sizeClassName} basic-input ` + className;
    const placeholder = props.placeholder || t('input.placeholder');

    return (
      <>
        <input
          ref={ref}
          className={newClassName}
          maxLength={maxLength}
          placeholder={placeholder}
          {...props}
        />
      </>
    );
  }
);

type WithOverlayProps = Props & {
  overlay: string;
};

const WithOverlay = forwardRef<HTMLInputElement, WithOverlayProps>(({ overlay, ...props }, ref) => {
  const sizeClassName = props.size === InputSize.MEDIUM ? 'input-small-font' : 'input-smaller-font';

  return (
    <>
      <InputText ref={ref} {...props} />
      <span className={sizeClassName} style={{ paddingRight: '0.5rem' }}>
        {overlay}
      </span>
    </>
  );
});

WithOverlay.displayName = 'WithOverlay';
InputText.displayName = 'InputText';

export default Object.assign(InputText, { WithOverlay });
