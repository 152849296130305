import { Fragment, MouseEvent, ReactNode } from 'react';
import { InputSize } from '../../../../../types/utilsEnums/input';
import './styles.scss';

type ChildrenLayoutProps = {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
};
const ChildrenLayout: React.FC<ChildrenLayoutProps> = ({ children, disabled, className }) => {
  const classes = ['input-element-wrapper-2'];

  if (disabled) {
    classes.push('input-disabled-bg-color');
  }

  if (!disabled) {
    classes.push('bg-white');
  }

  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

type Props = {
  leftSection?: ReactNode;
  error?: ErrorType;
  disabled?: boolean;
  size?: InputSize;
  width?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  children: ReactNode[] | ReactNode;
};

const InputWrapperPlainLabel = ({
  leftSection,
  error,
  disabled = false,
  size = InputSize.MEDIUM,
  width,
  onClick,
  children
}: Props) => {
  const borderColor = error ? 'error-border-color' : 'input-border-color';

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (disabled) e.stopPropagation();
    if (!onClick) return;
    onClick(e);
  };

  const filteredChildren =
    children && Array.isArray(children) ? children.filter((node: any) => !!node) : [];

  return (
    <div
      className={`input-wrapper-plain flex ${borderColor} ${size} ${
        disabled ? 'input-disabled-bg-color' : 'bg-white'
      }`}
      onClick={handleOnClick}
      style={{ minWidth: width, width }}>
      {leftSection && (
        <div className={`image-wrapper-input ${borderColor} ${size}`}>{leftSection}</div>
      )}
      {filteredChildren.map((node, index) => (
        <Fragment key={index}>
          <ChildrenLayout disabled={node.props.disabled} className={node.props.wrapperClassName}>
            {node}
          </ChildrenLayout>
          {index < (filteredChildren as ReactNode[]).length - 1 && <div className='divider-line' />}
        </Fragment>
      ))}
      {!Array.isArray(children) && <ChildrenLayout>{children}</ChildrenLayout>}
    </div>
  );
};

export default InputWrapperPlainLabel;
