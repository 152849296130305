import SlideToggle from 'components/ui/formComponents/slideToggle/SlideToggle';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import Icon from 'components/ui/icon/Icon';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import { useTranslation } from 'react-i18next';
import { Column } from 'types/table';
import { ScoreInput } from '../components/DMResults/ScoreInput';

type useColumnsProps = {
  setMeasured: (id: string, isMeasured: boolean) => void;
  setImpactScore: (id: string, impactScore: number) => void;
  setFinancialScore: (id: string, financialScore: number) => void;
};

export const useColumns = ({ setMeasured, setImpactScore, setFinancialScore }: useColumnsProps) => {
  const { t } = useTranslation();

  const columns: Column[] = [
    {
      title: t('doubleMateriality.category'),
      dataIndex: 'category',
      key: 'category',
      width: '10%',
      render: (category: string) => t(`breadcrumb.${category}`)
    },
    {
      title: t('doubleMateriality.topic'),
      dataIndex: 'topic',
      key: 'topic',
      width: '20%'
    },
    {
      title: t('doubleMateriality.subtopic'),
      dataIndex: 'subtopic',
      key: 'subtopic'
    },
    {
      title: t('doubleMateriality.financialScore'),
      dataIndex: 'financialScore',
      key: 'financialScore',
      render: (financialScore: string | null, row) => (
        <ScoreInput
          subtopicId={row.id}
          score={financialScore}
          onChangeScore={setFinancialScore}
          icon='money'
        />
      )
    },
    {
      title: t('doubleMateriality.impactScore'),
      dataIndex: 'impactScore',
      key: 'impactScore',
      render: (impactScore: string | null, row) => (
        <ScoreInput
          subtopicId={row.id}
          score={impactScore}
          onChangeScore={setImpactScore}
          icon='earth'
        />
      )
    },
    {
      title: t('doubleMateriality.material'),
      dataIndex: 'material',
      key: 'material',
      width: '10%',
      align: 'center',
      render: (material: boolean) => (
        <div className='flex justify-center'>
          <SlideToggle checked={material} setChecked={() => null} />
        </div>
      )
    },
    {
      title: (
        <div className='flex gap-1'>
          <span>{t('doubleMateriality.measured')}</span>
          <TooltipWrapper text={t('doubleMateriality.measuringExplanation')}>
            <Icon icon='information' color='pending' size='small' />
          </TooltipWrapper>
        </div>
      ),
      dataIndex: 'measured',
      key: 'measured',
      width: '15%',
      render: (measured: boolean, row) => (
        <FormSelect
          disabled={!row.material}
          value={{
            id: measured ? 'measured' : 'not_measured',
            name: measured
              ? t('doubleMateriality.imMeasuring')
              : t('doubleMateriality.imNotMeasuring')
          }}
          onChange={(option) => {
            setMeasured(row.id, option.id === 'measured');
          }}
          options={[
            {
              id: 'measured',
              name: t('doubleMateriality.imMeasuring')
            },
            {
              id: 'not_measured',
              name: t('doubleMateriality.imNotMeasuring')
            }
          ]}
        />
      )
    }
  ];

  return { columns };
};
