import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import { useColumns } from './hooks/useColumns';
import { useObtainClients } from './hooks/useClients';
import { useContext, useState } from 'react';
import { FilterDatesContext } from 'context/filterDatesContext';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';
import { useTranslation } from 'react-i18next';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import CardCTA from 'components/ui/cards/cardCTA/CardCTA';
import Button from 'components/ui/button/Button';
import { useDispatch } from 'react-redux';
import { setNotification } from 'actions/notification';
import useDebounceValue from 'customHooks/useDebounceValue';
import { InputNuvoClients } from './InputNuvoClients';

export const DMClients = () => {
  const { startDate } = useContext(FilterDatesContext);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceValue(searchValue);
  const { t: t_esg } = useTranslation('', { keyPrefix: 'esgMetrics' });
  const { t: t_dm } = useTranslation('', { keyPrefix: 'doubleMateriality' });
  const { t: t_notify } = useTranslation('', { keyPrefix: 'notification' });
  const columns = useColumns();
  const dispatch = useDispatch();

  const year = startDate.split('/')[2];
  const { data, fetchNextPage, isLoading } = useObtainClients({
    year,
    company_name: debounceSearchValue
  });
  const { clients, total } = data ?? {};

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 55vh'
      }}>
      <CardCTA>
        <CardCTA.Header>
          <span className='headline4-font'>{t_dm('clientTitle')}</span>
          <span className='subtitle3-font'>{t_dm('clientSubtitle')}</span>
        </CardCTA.Header>
        <CardCTA.Buttons>
          <InputNuvoClients />
          <Button
            lookAndFeel={'primary'}
            text={t_dm('context.copyLinkQuestionnaire')}
            size='small'
            onClick={() => {
              navigator.clipboard.writeText(
                'https://docs.google.com/forms/d/e/1FAIpQLSe7NyZ5IKE8sAptLm4O-VSP08fwRkJPgHU_VtU6ySZn0VDB-g/viewform'
              );
              dispatch(setNotification(t_notify('copyOnBoard')));
            }}
          />
        </CardCTA.Buttons>
      </CardCTA>
      <InfiniteList
        fetchData={fetchNextPage}
        data={clients ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={total}
        header={
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center gap-2'>
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t_esg('filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>
            </div>
            <TotalLegend
              total={total ?? 0}
              loading={false}
              i18key={'doubleMateriality.context.clients'}
            />
          </div>
        }
      />
    </div>
  );
};
